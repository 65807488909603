import { all, fork, put, takeEvery, select } from "redux-saga/effects";
import { NotificationService } from "../../components";
import { APIServices } from "../../utils";
import actions from "./actions";
import reqActions from "../request/actions";
import authActions from "../auth/actions";

function* saga_LoadConfig(action: any) {
  try {
    let { page_size } = action.payload;
    let _pageNumber: Promise<any> = yield select(
      (state: any) => state.doc.page_number
    );
    let pageNumber = _pageNumber;
    let _pageSize: Promise<any> = yield select(
      (state: any) => state.doc.page_size
    );
    let pageSize = _pageSize;
    if (page_size) pageSize = page_size;
    let _filter: Promise<any> = yield select(
      (state: any) => state.doc.current_filter
    );
    let filter = _filter;
    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Doc.GetDoc(
      pageSize,
      pageNumber,
      filter
    );
    let response: any = _response;
    let { data } = response;

    if (data) {
      let { documents, total } = data;

      yield put(actions.action.loadConfigSuccess(documents, total));
      yield put(reqActions.action.updateRequestState(1));
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Tải mô tả chứng từ thất bại");
    }
  } catch (ex: any) {
    console.log("[Doc] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Tải mô tả chứng từ thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_CreateDoc(action: any) {
  try {
    let { doc_type, desc, is_ocr, has_signature, is_detect_table } =
      action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Doc.CreateDoc(
      doc_type,
      desc,
      is_ocr,
      has_signature,
      is_detect_table
    );
    let response: any = _response;
    let { data, code, message } = response;

    if (data && code === 1) {
      yield put(reqActions.action.updateRequestState(1));
      yield put(actions.action.loadConfig());
      NotificationService.success("Tạo mô tả chứng từ thành công");
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error(
        message ? message : "Tạo mô tả chứng từ thất bại"
      );
    }
  } catch (ex: any) {
    console.log("[Doc] saga_LoadData Error: ", ex);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error(
      ex && ex.data && ex.data.message
        ? ex.data.message
        : "Tạo mô tả chứng từ thất bại"
    );

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_UpdateDoc(action: any) {
  try {
    let { doc_id, doc_type, desc, is_ocr, has_signature, is_detect_table } =
      action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Doc.UpdateDoc(
      doc_id,
      doc_type,
      desc,
      is_ocr,
      has_signature,
      is_detect_table
    );
    let response: any = _response;
    let { data, code } = response;

    if (data && code === 1) {
      yield put(reqActions.action.updateRequestState(1));
      yield put(actions.action.loadConfig());
      NotificationService.success("Cập nhật mô tả chứng từ thành công");
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Cập nhật mô tả chứng từ thất bại");
    }
  } catch (ex: any) {
    console.log("[Doc] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Cập nhật mô tả chứng từ thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* saga_DeleteDoc(action: any) {
  try {
    let { doc_id } = action.payload;

    yield put(reqActions.action.updateRequestState(0));
    let _response: Promise<any> = yield APIServices.Doc.DeleteDoc(doc_id);
    let response: any = _response;
    let { data, code } = response;

    if (data && code === 1) {
      yield put(reqActions.action.updateRequestState(1));
      yield put(actions.action.loadConfig());
      NotificationService.success("Xoá mô tả chứng từ thành công");
    } else {
      yield put(reqActions.action.updateRequestState(-1));
      NotificationService.error("Xoá mô tả chứng từ thất bại");
    }
  } catch (ex: any) {
    console.log("[Doc] saga_LoadData Error: ", ex.message);
    yield put(reqActions.action.updateRequestState(-1));
    NotificationService.error("Xoá mô tả chứng từ thất bại");

    if (ex.status === 401) {
      window.localStorage.clear();
      yield put(authActions.action.logOut());
    }
  }
}

function* listen() {
  yield takeEvery(actions.types.LOAD_CONFIG, saga_LoadConfig);
  yield takeEvery(actions.types.UPDATE_FILTER, saga_LoadConfig);
  yield takeEvery(actions.types.UPDATE_PAGE_NUMBER, saga_LoadConfig);
  yield takeEvery(actions.types.UPDATE_PAGE_SIZE, saga_LoadConfig);
  yield takeEvery(actions.types.CREATE_DOC, saga_CreateDoc);
  yield takeEvery(actions.types.UPDATE_DOC, saga_UpdateDoc);
  yield takeEvery(actions.types.DELETE_DOC, saga_DeleteDoc);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
