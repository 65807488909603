import { useEffect, useState } from "react";
import { Spin, Button } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Line, RiEditLine } from "react-icons/ri";
import { RiAddCircleLine } from "react-icons/ri";

import { useActions } from "../../redux";
import { error } from "../../components/notification";
import { PaginationComponent } from "../../components/pagination";
import { showConfirm } from "../../components/modal";
import { DocumentModalComponent } from "./component";
import FilterComponent from "../../components/filterComponent";
import { DATE_RANGE_FORMAT } from "../../common";

var role = localStorage.getItem("role");
const dateFormat = "DD-MM-YYYY - HH:mm:ss";
const options = [
  { label: "Loại chứng từ", value: 1 },
  { label: "Mô tả chứng từ", value: 2 },
];

export const DocumentPage = () => {
  const dispatch = useDispatch();
  const actions = useActions();
  const [modalMode, setModalMode] = useState<any>(null);
  const [docType, setDocType] = useState<any>("");
  const [docId, setDocId] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const [isOcr, setIsOcr] = useState<any>(true);
  const [hasSignature, setHasSignature] = useState<any>(false);
  const [isDetectTable, setIsDetectTable] = useState<any>(false);
  const requestState = useSelector((state: any) => state.request.request_state);
  let config = useSelector((state: any) => state.doc.config);
  let totalRecord = useSelector((state: any) => state.doc.total_record);
  let pageNumber = useSelector((state: any) => state.doc.page_number);
  let pageSize = useSelector((state: any) => state.doc.page_size);

  useEffect(() => {
    if (!config) dispatch(actions.DocActions.loadConfig());
  }, [actions.DocActions, config, dispatch]);

  const handleChangePageNumber = (page_number: any) => {
    dispatch(actions.DocActions.updatePageNumber(page_number));
  };

  const handleChangePageSize = (page_size: any) => {
    dispatch(actions.DocActions.updatePageSize(page_size));
  };

  const handleCreate = () => {
    setIsOcr(true);
    setHasSignature(false);
    setIsDetectTable(false);
    setModalMode("create");
  };

  const handleUpdate = (
    doc_id: any,
    doc_type: any,
    desc: any,
    is_ocr: any,
    has_signature: any,
    is_detect_table: any
  ) => {
    setDocId(doc_id);
    setDocType(doc_type);
    setDesc(desc);
    setIsOcr(is_ocr);
    setHasSignature(has_signature);
    setIsDetectTable(is_detect_table);
    setModalMode("update");
  };

  const handleDelete = (doc_id: any) => {
    showConfirm({
      title: "Chắc chắn xoá mô tả chứng từ này?",
      content: "",
      onOk: () => {
        dispatch(actions.DocActions.deleteDoc(doc_id));
      },
      onCancel: () => {},
    });
  };

  const handleCancel = () => {
    setDocType("");
    setDesc("");
    setDocId("");
    setModalMode(null);
  };

  const handleSave = () => {
    if (docType === "") return error("Vui lòng nhập đầy đủ thông tin");
    if (modalMode === "create") {
      dispatch(
        actions.DocActions.createDoc(
          docType,
          desc,
          isOcr,
          hasSignature,
          isDetectTable
        )
      );
    }
    if (modalMode === "update") {
      dispatch(
        actions.DocActions.updateDoc(
          docId,
          docType,
          desc,
          isOcr,
          hasSignature,
          isDetectTable
        )
      );
    }

    setDocType("");
    setDesc("");
    setModalMode(null);
  };

  const onSearch = (
    fromTime: moment.Moment,
    toTime: moment.Moment,
    searchText: string,
    searchType: number
  ) => {
    dispatch(
      actions.DocActions.updateFilter({
        fromTime: moment(fromTime).format(DATE_RANGE_FORMAT),
        toTime: moment(toTime).format(DATE_RANGE_FORMAT),
        searchText,
        searchType,
      })
    );
  };

  var configData: any[] = [];
  if (config && typeof config === "object") {
    Object.keys(config).forEach((doc: any, _: any) => {
      configData.push(config[doc]);
    });
  }

  if (!role || role === "") role = localStorage.getItem("role");

  return (
    <div className="AppPage">
      <div className="PageTitle">Document Management</div>
      <div className="head-section" style={{ padding: 0 }}>
        <FilterComponent options={options} onSearch={onSearch} />
        <div className="CreateButtonContainer">
          <Button type="primary" onClick={handleCreate}>
            <RiAddCircleLine /> Tạo Document
          </Button>
        </div>
      </div>

      <Spin
        spinning={requestState === 0}
        size="large"
        style={{ position: "static" }}
      >
        <div className="TableContainer">
          <table className="table table-striped sticky-header">
            <thead>
              <tr>
                <th>Loại chứng từ</th>
                <th>Mô tả chứng từ</th>
                <th>Ocr</th>
                <th>Nhận diện chữ ký</th>
                <th>Detect Table</th>
                <th>Thời gian tạo</th>
                <th>Tạo bởi</th>
                <th>Thời gian cập nhật</th>
                <th>Cập nhật bởi</th>
                {/* <th>Thời gian xoá</th> */}
                <th>Xoá bởi</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {configData &&
                configData.length > 0 &&
                configData.map((item: any, index: any) => {
                  return (
                    <tr key={`row_${index}`}>
                      <td>{item.doc_type}</td>
                      <td>{item.description}</td>
                      <td>{item.is_ocr ? "Có" : "Không"}</td>
                      <td>{item.has_signature ? "Có" : "Không"}</td>
                      <td>{item.is_detect_table ? "Có" : "Không"}</td>
                      <td>{moment(item.created_at).format(dateFormat)}</td>
                      <td>{item.created_by}</td>
                      <td>
                        {item.updated_at &&
                          moment(item.updated_at).format(dateFormat)}
                      </td>
                      <td>{item.updated_by}</td>
                      {/* <td>
                        {item.deleted_at &&
                          moment(item.deleted_at).format(dateFormat)}
                      </td> */}
                      <td>{item.deleted_by}</td>
                      <td>
                        {!item.deleted_at && (
                          <div className="ButtonContainer">
                            <Button
                              type="default"
                              className="SearchButton"
                              onClick={() =>
                                handleUpdate(
                                  item.id,
                                  item.doc_type,
                                  item.description,
                                  item.is_ocr,
                                  item.has_signature,
                                  item.is_detect_table
                                )
                              }
                            >
                              <RiEditLine />
                              Cập nhật
                            </Button>
                            {role === "ADMIN_OCR" && (
                              <Button
                                type="primary"
                                className="ResetButton"
                                onClick={() => handleDelete(item.id)}
                                danger
                              >
                                <RiDeleteBin6Line /> Xoá
                              </Button>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              {(!configData || configData.length === 0) && (
                <tr>
                  <td colSpan={9}>Không có dữ liệu</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PaginationComponent
          totalRecord={totalRecord}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
        />
        <DocumentModalComponent
          modalMode={modalMode}
          docType={docType}
          desc={desc}
          onChangeDocType={setDocType}
          onChangeDesc={setDesc}
          isOcr={isOcr}
          onChangeIsOcr={setIsOcr}
          hasSignature={hasSignature}
          isDetectTable={isDetectTable}
          onChangeHasSignature={setHasSignature}
          onChangeIsDetectTable={setIsDetectTable}
          onCancel={handleCancel}
          onSave={handleSave}
        />
      </Spin>
    </div>
  );
};
