const prefix = "doc/";

const types = {
  RESET: prefix + "reset",

  UPDATE_FILTER: prefix + "update_filter",
  UPDATE_PAGE_NUMBER: prefix + "update_page_number",
  UPDATE_PAGE_SIZE: prefix + "update_page_size",
  LOAD_CONFIG: prefix + "load_config",
  LOAD_CONFIG_SUCCESS: prefix + "load_config_success",
  LOAD_DATA: prefix + "load_data",
  LOAD_DATA_SUCCESS: prefix + "load_data_success",
  CREATE_DOC: prefix + "create_doc",
  UPDATE_DOC: prefix + "update_doc",
  DELETE_DOC: prefix + "delete_doc",
};

const action = {
  reset: () => {
    return {
      type: types.RESET,
    };
  },

  updateFilter: (filter: any) => {
    return {
      type: types.UPDATE_FILTER,
      payload: { filter },
    };
  },
  updatePageNumber: (page_number: any) => {
    return {
      type: types.UPDATE_PAGE_NUMBER,
      payload: { page_number },
    };
  },
  updatePageSize: (page_size: any) => {
    return {
      type: types.UPDATE_PAGE_SIZE,
      payload: { page_size },
    };
  },
  loadConfig: (page_size: any = 10) => {
    return {
      type: types.LOAD_CONFIG,
      payload: { page_size },
    };
  },
  loadConfigSuccess: (config: any, total: any) => {
    return {
      type: types.LOAD_CONFIG_SUCCESS,
      payload: { config, total },
    };
  },

  loadData: () => {
    return {
      type: types.LOAD_DATA,
    };
  },
  loadDataSuccess: (paging: any, data: any) => {
    return {
      type: types.LOAD_DATA_SUCCESS,
      payload: { paging, data },
    };
  },
  createDoc: (
    doc_type: any,
    desc: any,
    is_ocr: any,
    has_signature: any,
    is_detect_table: any
  ) => {
    return {
      type: types.CREATE_DOC,
      payload: { doc_type, desc, is_ocr, has_signature, is_detect_table },
    };
  },
  updateDoc: (
    doc_id: any,
    doc_type: any,
    desc: any,
    is_ocr: any,
    has_signature: any,
    is_detect_table: any
  ) => {
    return {
      type: types.UPDATE_DOC,
      payload: {
        doc_id,
        doc_type,
        desc,
        is_ocr,
        has_signature,
        is_detect_table,
      },
    };
  },
  deleteDoc: (doc_id: any) => {
    return {
      type: types.DELETE_DOC,
      payload: { doc_id },
    };
  },
};

const actions = {
  types,
  action,
};

export default actions;

export const DocActions = action;
